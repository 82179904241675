:root {

    /*    
    Font Weight: 100 (thin), 400 (normal), 700 (bold), 900 (heavy)
    Final

    */
    --font-size-small: 24px;    /*16px*/
    --font-size-medium: 30px;  /*20px*/
    --font-size-large: 30px;   /*30px*/
    --font-size-larger: 50px;   /*30px*/
    --font-size-extra: 60px;   /*50px*/

    --color-yellow: #F2B500;
    --color-yellow-pale: #FFC862;
    --color-green: #1B7E06;
    --color-green-light: rgb(27, 126, 6, 0.2);
    --color-blue: #051A50;


    --color-copper: #ED823D;
    --color-copper-dark: #E37533;
    --color-copper-light: #FC9749;
    --color-copper-green: #4E7566;
    --color-copper-green-dark: #3d5c50;
    --color-orange: #F09C32; /*This color is hard coded in svg and maybe other places*/
    --color-orange-light: #FFE9C0;
    --color-blue-dark: #2676d8;
    --color-blue-light: #EBF3FD;
    --color-white: #FFFFFF;
    --color-grey: #ececec;
    --color-black: #000000;
    --color-black-light: rgb(17, 17, 17, 0.98);
    --color-grey-backgrond: #F8F8f8;
    --color-grey-light: #CCCCCC;
    --color-grey-dark: #3d3d3d;

    --box-shadow-1: 0px 0px 40px rgba(0, 0, 0, 0.1);
    --box-shadow-header: 0px 5px 5px rgba(0, 0, 0, 0.05);
    --box-shadow-bottom: 0px -5px 5px rgba(0, 0, 0, 0.2);
    --arrow-drop-shadow: 0px 0px 10px rgb(154, 154, 154);

    --margin-large: 100px;
}

@font-face {
    font-family: 'PhillySans';
    src: local('PhillySans'), url(./Fonts/PhillySans.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'Rajdhani-Bold';
    src: local('Rajdhani-Bold'), url(./Fonts/Rajdhani-Bold.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'Rajdhani';
    src: local('Rajdhani'), url(./Fonts/Rajdhani-Medium.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}

#root {
    background-color: var(--color-grey);
}

html {
    background-color: var(--color-grey);
}
body {
    background-color: var(--color-grey);
}


.App {
    font-size: var(--font-size-small);
    color: var(--color-black-light);
}

.About, .Contact {
    background-color: var(--color-grey);
    overflow: hidden;
    min-height: 100vh;
    position: relative;
}
.Home, .Page {
    background-color: var(--color-grey);
    overflow: hidden;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 100px;
}
.Page {
    --margin-top: 50px;
    margin-bottom: 100px;
    gap: 50px;
}


.FooterClass {
    background-color: var(--color-black);
}


p, h1, h2, h3, h4, h5 {
    margin: 0px;
}

a {
    text-decoration: none;
}

/*
*********************************************************************************************************************
*********************************************************************************************************************
*********************************************************************************************************************
    Header
*********************************************************************************************************************
*********************************************************************************************************************
*********************************************************************************************************************
*/

.Header {
    background-color: var(--color-white);
    z-index: 99999999999;

    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
}

.header {
    background-color: var(--color-white);
}

.header-desktop {
    height: 70px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow-header);
}

.header-desktop-logo {
    height: 50px;
    width: auto;
    margin-left: 30px;
    display: flex;
    align-items: center;
}

.test-title {
    margin-bottom: -20px;
    font-size: 60px;
    color: black;
    font-family: "Rajdhani-Bold";
}

.page-banner-title {
    font-size: 60px;
    color: black;
    color: var(--color-green);
    color: var(--color-grey);
    font-family: "Rajdhani-Bold";
    text-align: center;
    
}

#hero-id {
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15);
    font-size: 80px;
    margin-bottom: 0px;
}

#insta-handle-id:hover {
    cursor:pointer;
}

.title-logo {
    font-size: var(--font-size-small);
    color: black;
    font-family: "Rajdhani-Bold";
}
.title-logo:hover {
    color: var(--color-green);
    cursor: pointer;
}

.logo-container {
    display: flex;
    gap: 0px;
    align-items: center;
    flex: 1;
}

.header-desktop-links {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    gap: 30px;
    align-items: center;
    margin-right: 30px;
}

.header-desktop-link {
    text-decoration: none;
    color: var(--color-black-light);
    font-size: var(--font-size-small);
    font-family: 'Rajdhani-Bold';
    white-space: nowrap;
}

.header-desktop-link:hover {
    color: var(--color-green);
}

.header-desktop-link:active {
    color: var(--color-blue);
}

.header-desktop-button {
    color: var(--color-white);
    background-color: var(--color-black);
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 999px;
    font-size: var(--font-size-small);
    font-weight: 700;
}

.header-drawer-link-flex {
    display: flex;
    gap: 15px;
    align-items: center;
}

.header-drawer-link-caret {
    width: 10px;
    height: auto;
    --padding-top: 2px;
    opacity: 0;
    rotate: 180deg;
}

.header-mobile {
    background-color: var(--color-white);
    display: none;
    align-items: center;
    width: 100%;
    overflow: hidden;
    box-shadow: var(--box-shadow-header);
}

.header-mobile-clickbox {
    height: 70px;
    width: 70px;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.header-mobile-clickbox:hover {
    cursor: pointer;
}
.header-mobile-clickbox:active {
    --background-color: var(--color-yellow);
}
.header-mobile-clickbox:focus {
    --background-color: var(--color-yellow);
}

.header-mobile-icon {
    height: 100%;
    width: auto; 
}

.header-mobile-logo-container {
    height: 40px;
    flex: 1;
    padding-left: 20px;
}

.header-mobile-logo {
    height: 100%;
    width: auto;
    box-sizing: border-box;
}

.header-mobile-logo:hover {
    cursor: pointer;
}

.header-mobile-button {
    color: var(--color-white);
    background-color: var(--color-black);
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 999px;
    font-size: var(--font-size-small);
    font-weight: 700;
    margin-right: 18px;
    text-align: center;
}

.header-drawer {
    background-color: var(--color-black-light);
    padding: 30px;
    box-sizing: border-box;
    width: calc(100% - 0px);
    height: 100%;
    position: fixed;
    top: 70px;
    box-shadow: 0px 0px 20px black;
    z-index: -1;
    transform: translateX(calc(100%  - 25px));
    opacity: 0;
    display: block;
}

.header-drawer-links {
    display: flex;
    flex-direction: column;
    gap: 40px;
    float: right;
    align-items: flex-end;

}
Link {
    display: inline;
}

.header-drawer-link {
    text-decoration: none;
    color: var(--color-white);
    letter-spacing: 2px;
    font-weight: 100;
    font-size: var(--font-size-medium);
    --border-bottom: 1px solid var(--color-white);
    --padding-bottom: 2px;
    line-height: 0;
    font-family: 'Rajdhani-Bold';
}

@-webkit-keyframes drawerIn {
    0% { 
        -webkit-transform: translateX(-100% - 25px);
     }
     10% {
        opacity: 1;
     }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
    }
}
@keyframes drawerIn {
    0% { 
        -moz-transform: translateX(-100% - 25px);
        -ms-transform: translateX(-100% - 25px);
        -o-transform: translateX(-100% - 25px);
        transform: translateX(-100% - 25px);
     }
     10% {
        opacity: 1;
     }
    100% {
        opacity: 1;
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }
}

@-webkit-keyframes drawerOut {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0%);
    }
    90% {
        opacity: 1;
     }
    100% {
        opacity: 0;
        -webkit-transform: translateX(-100% - 25px);
    }
}

@keyframes drawerOut {
    0% {
        opacity: 1;
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }
    90% {
        opacity: 1;
     }
    100% {
        opacity: 0;
        -moz-transform: translateX(-100% - 25px);
        -ms-transform: translateX(-100% - 25px);
        -o-transform: translateX(-100% - 25px);
        transform: translateX(-100% - 25px);
    }
}

/*
*********************************************************************************************************************
*********************************************************************************************************************
*********************************************************************************************************************
    Footer
*********************************************************************************************************************
*********************************************************************************************************************
*********************************************************************************************************************
*/

.phone-number {
    text-decoration: underline;
    color: var(--color-green);
}

.phone-number:hover {
    cursor: pointer;
}

.footer {
    display:flex;
    flex-direction: column;
    gap: 30px;
    padding: 50px;
    padding-top: 70px;
    padding-bottom: 70px;
    align-items: center;
}

.footer-copyright {
    font-weight: 400;
    color: var(--color-white);
    font-size: var(--font-size-small);
}

/*
*********************************************************************************************************************
*********************************************************************************************************************
*********************************************************************************************************************
    Basic Elements
*********************************************************************************************************************
*********************************************************************************************************************
*********************************************************************************************************************
*/

.trio-of-images {
    --display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
    --display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
    --display: -ms-flexbox;      /* TWEENER - IE 10 */
    display: -webkit-flex;     /* NEW - Chrome */
    display: flex;
    gap: 15px;
    width: 100%;
    flex-direction: row;
    --min-width: 0;
}
.standalone-image {
    width: 100%;
    height: auto;
    --border: 10px solid white;
    box-sizing: border-box;
    --border-radius: 10px;
    flex: 1 1 0;
    min-width: 0;
}

.banner-container {
    width: 100%;
    position: relative;
    display: flex;
}
.banner {
    width: 100%;
    box-sizing: border-box;
}
.banner-shadow {
    background-color: rgb(0,0,0,0.25);
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    box-sizing: border-box;
}
.banner-title {
    width: 100%;
    text-align: center;
    font-size: 100px;
    font-weight: bold;
    padding-left: 50px;
    padding-right: 50px;
    box-sizing: border-box;
    font-family: "PhillySans";

    color: white;
    text-shadow: 0 0 20px black;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.footer-text-container {
    display: flex;
    flex-direction: row;
    gap: 50px;
    font-family: 'Rajdhani';
}
.footer-text {
    color: var(--color-grey);
}
.home-section {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
    box-sizing: border-box;
    width: 1000px;
    max-width: 1000px;
    margin: 0 auto;
    --align-items: center;
    --text-align: center;
}
.home-section-2 {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    gap: 60px;
    align-items: center;
    --min-width: 100%;
    box-sizing: border-box;
    width: 1000px;
    margin: 0 auto;
}
.home-art-products-container {
    width: 100%;
    max-width: 100%;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    gap: 20px;
    display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;      /* TWEENER - IE 10 */
    display: -webkit-flex;     /* NEW - Chrome */
    display:flex;
}
.home-art-products-container-row {
    width: 100%;
    box-sizing: border-box;
    flex-direction: row;
    gap: 20px;
    display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;      /* TWEENER - IE 10 */
    display: -webkit-flex;     /* NEW - Chrome */
    display:flex;
}
.home-art-product {
    width: 100%;
    flex: 1 1 0;
    min-width: 0;
}
.title-sub {
    font-size: var(--font-size-small);
}

.profile-picture-container {
    position: relative;
    align-items: center;

}
.profile-picture {
    width: 200px;
    height: 200px;
    border-radius: 99999px;
    border: 10px solid white;
    position: absolute;
    left: 50%;
    margin-left: -100px;
    top: -110px;
}

.instagram-logo {
    width: 100px;
    height: 100px;
    margin: 0 auto;
}

.home-centered {
    display: flex;
    gap: 50px;
    flex-direction: column;
    text-align: center;
    background-color: rgb(39, 39, 39);
    padding-top: 100px;
    padding-bottom: 100px;
}

#firstCardId {
    --margin-top: 150px;
}

.card-basic {
    background-color: var(--color-white);
    width: 400px;
    margin: 0 auto;
    box-shadow: var(--box-shadow-1);
    border-radius: 10px;
    box-sizing: border-box;
    --margin-top: 100px;
    --margin-bottom: 100px;
    overflow: auto;
    position: relative;
    z-index: 1;
}

.card-with-image-ui {
    background-color: var(--color-white);
    width: 100%;
    margin: 0 auto;
    box-shadow: var(--box-shadow-1);
    border-radius: 10px;
    --box-sizing: border-box;
    --margin-top: 100px;
    --margin-bottom: 100px;
    overflow: auto;
    position: relative;
    overflow: visible;
}

.card-wide {
    background-color: var(--color-white);
    width: 60%;
    margin: 0 auto;
    box-shadow: var(--box-shadow-1);
    border-radius: 10px;
    box-sizing: border-box;
    margin-top: 100px;
    margin-bottom: 100px;
    overflow: auto;
}

.card-cardless {
    width: 60%;
    margin: 0 auto;
    border-radius: 10px;
    box-sizing: border-box;
    margin-top: 50px;
    margin-bottom: 50px;
    overflow: auto;
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.home-cards-flexbox {
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 100px;
    padding-bottom: 100px;
    gap: 100px;
    position: relative;
    box-sizing: border-box;
}

.home-cards-flexbox .paragraph {
    flex: 1;
}

.website-cards-flexbox {
    display: flex;
    flex-direction: column;
    --width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 100px;
    padding-bottom: 100px;
    gap: 50px;
    position: relative;
    box-sizing: border-box;
}
.website-cards-flexbox-for-contact {
    display: flex;
    flex-direction: column;
    --width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    gap: 50px;
    position: relative;
    box-sizing: border-box;
}

.section-title {
    background-color: var(--color-orange);
    background-color: var(--color-yellow);
    padding: 100px;
    padding-left: 0px;
    padding-right: 0px;
    font-style: italic;
    text-shadow: 0px 0px 10px rgb(0, 0, 0, 0.5);
}

.padded-flexbox {
    display: flex;
    flex-direction: column;
    gap:10px;
    padding: 30px;
}

.padded-flexbox-large {
    display: flex;
    flex-direction: column;
    gap:30px;
    padding: 30px;
    height: 100%;
    box-sizing: border-box;
}

.card-cardless-intro {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.title-1 {
    font-size: var(--font-size-4);
    font-weight: 900;
    color: var(--color-white);
    color: var(--color-orange-light);
    text-align: center;
}

.title-extra {
    font-size: var(--font-size-extra);
    --padding: 30px;
    --margin-bottom: 30px;
    --border-bottom: 1px solid var(--color-grey-light);
    --text-align: center;
    font-weight: 700;
}

.title-larger {
    font-size: var(--font-size-larger);
    --padding: 30px;
    --margin-bottom: 30px;
    --border-bottom: 1px solid var(--color-grey-light);
    --text-align: center;
    font-weight: 700;
}

.title-large {
    font-size: var(--font-size-large);
    --padding: 30px;
    --margin-bottom: 30px;
    --border-bottom: 1px solid var(--color-grey-light);
    --text-align: center;
    font-weight: 700;
}

.title-medium {
    font-size: var(--font-size-medium);
    color:#373737;
    line-height: 35px;
    font-family: 'Rajdhani-Bold';
    margin-top: 20px;
    margin-top: 0px;
}

.title-small {
    font-size: var(--font-size-small);
    color:#464646;
    line-height: 35px;
    font-family: 'Rajdhani';
}

.paragraph {
    font-size: var(--font-size-small);
    color: var(--color-black);
    line-height: 175%;
    --text-align: center;
    font-weight: 400;
}

.text-bolded {
    font-size: var(--font-size-1);
    color: var(--color-black);
    text-align: center;
    --font-weight: 700;
}

.text-bolded-content {
    padding: 30px;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.button {
    color: var(--color-white);
    background-color: var(--color-blue);
    font-size: var(--font-size-small);
    font-weight: 900;
    border: none;
    padding: 20px;
    --width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    border-radius: 10px;
    box-shadow: 0px 3px 4px 2px rgb(0, 0, 0, 0.25);
    white-space: nowrap;
}

.button:hover {
    cursor: pointer;
    background-color: var(--color-blue-dark);
}

.button-bottom {
    color: var(--color-white);
    background-color: var(--color-blue);
    font-size: var(--font-size-small);
    font-weight: 900;
    border: none;
    padding: 15px;
    min-width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    border-radius: 10px;
    white-space: nowrap;
    flex: 1;
}

.website-bottom-flex a {
    width: 100%;
}

.button-botttom span {
    flex: 1;
}

.button-container {
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 30px;
    width: calc(100% - 60px);
}

.button-chevron {
    height: 15px;
    width: auto;
}

.trio-content {
    display: flex;
    gap: 50px;
    padding: 30px;
    padding-top: 0px;
}

.trio-content > * {
    flex: 1;
}

.trio-item {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.honeycomb-preview {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 100%;
    width: auto;
    position: relative;
}

.honeycomb-preview-banner {
    background-color: var(--color-orange-light);
    height: 60%;
    width: 100%;
    position: absolute;
    top: 20%;
}

.honeycomb-preview-and-banner {
    position: relative;
    height: 100px;
    margin-top: 30px;
    --margin-bottom: 30px;
}

.honeycomb-contact-container {
    width: 50%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
}

.honeycomb-contact {
    height: 100%;
    width: 100%;
    border-radius: 99999px;

}

.contact-flex {
    display: flex;
    align-items: center;
}

.contact-label {
    font-size: var(--font-size-1);
    font-weight: 700;
}

.contact-value {
    font-size: var(--font-size-medium);
    font-weight: 700;
    color: var(--color-blue);
    text-decoration: underline;
}
.contact-value-black {
    font-size: var(--font-size-medium);
    font-weight: 700;
}
.contact-value[disabled] {
    background-color: #ebebeb;
    text-decoration: none;
    color: #919191;
    pointer-events: all !important;
    cursor: not-allowed;
    padding: 5px;
    border-radius: 5px;
    padding-left: 25px;
    padding-right: 25px;
    display: inline-block;
    opacity: 0.75;
}
.button-bottom[disabled] {
    background-color: #ebebeb;
    text-decoration: none;
    color: #919191;
    pointer-events: all !important;
    cursor: not-allowed;
    border-radius: 5px;
    --display: inline-block;
    opacity: 0.75;

}
.title-medium[disabled] { 
    background-color: #ebebeb;
    text-decoration: none;
    color: #919191;
    pointer-events: all !important;
    cursor: not-allowed;
    padding: 5px;
    border-radius: 5px;
    --padding-left: 25px;
    --padding-right: 25px;
    --display: inline-block;
    width: 100%;
    text-align: center;
    opacity: 0.75;

}


.contact-content-below-line {
    border-top: 1px solid var(--color-grey-light);
}

.about-image {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
}

.about-image-flex {
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    margin-top: 50px;
    position: relative;
}

.checklist-content {
    --border-top: 1px solid var(--color-grey-light);
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.content-below-line {
    border-top: 1px solid var(--color-grey-light);
    padding: 30px;
}

.check-and-text {
    display:flex;
    align-items: center;
    justify-content: center;

    gap: 10px;
}

.check {
    height: 15px;
    width: auto;
}

.phone-icon {
    height: 20px;
    width: auto;
}

.website-banner {
    background-color: var(--color-yellow);
    padding-top: 20px;
    padding-bottom: 30px;
    width: 100%;
    display: flex;
}

.website-banner-image-wrapper {
    position: relative;
    height: 0 !important;
    /* Formula is: (height / width * 100%) */
    padding-top: calc(529 / 881 * 33%);
    width: 33%;
}

.website-banner-image {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
}

.website-banner-image-wrapper:first-child {
    transform: translateX(-33%);
}
.website-banner-image-wrapper:last-child {
    transform: translateX(33%);
}

.decorative-border {
    display: flex;
    align-items: center;
    gap: 10px;
    --margin-left: 30px;
    --margin-right: 30px;
}

.decorative-border-line {
    margin-top: 10px;

    background-color: rgba(255, 255, 255, 0);
    border-bottom: 2px solid var(--color-yellow);
    margin-bottom: 10px;
    flex: 1;
}

.decorative-border-square {
    width: 10px;
    height: 10px;
    background-color: var(--color-yellow);
    transform: rotate(45deg);
}

.website-fullscreen-left, .website-fullscreen-right {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    border: 20px solid var(--color-white);
    box-sizing: border-box;
    box-shadow: var(--box-shadow-1);
    border-radius: 10px;
    display: block;
}

.card-feature {
    background-color: var(--color-white);
    width: 100%;
    margin: 0 auto;
    box-shadow: var(--box-shadow-1);
    border-radius: 10px;
    box-sizing: border-box;
    overflow: auto;
    position: relative;
}

.features-flex-all {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 60%;
    margin: 0 auto;
    margin-top: 100px;
    margin-bottom: 100px;
}

.features-flex-two {
    display: flex;
    flex-direction: row;
    gap: 50px;
}

.title-feature {
    font-size: var(--font-size-2);
    text-align: center;
    font-weight: 900;
    flex: 1;
}

.why-bee-flexbox {
    display: flex;
    gap: 50px;
    width: 80%;
    margin-top: 100px;
    margin-bottom: 100px;
    margin-left: auto;
    margin-right: auto;
}

.card-why-bee {
    background-color: var(--color-white);
    width: 100%;
    margin: 0 auto;
    box-shadow: var(--box-shadow-1);
    border-radius: 10px;
    box-sizing: border-box;
    overflow: auto;
    position: relative;
}

.card-why-bee-image {
    width: 75%;
    height: auto;
    margin: auto;
}

.card-why-bee .paragraph {
    flex: 1;
}

.website-flex {
    display: flex;
}

.website-bottom {
    bottom: 0;
    width: 100%;
    position: fixed;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow-bottom);
    text-align: center;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    display: none; /*remove for mobile */
}

.website-bottom-flex {
    display: flex;
    gap: 15px;
}

.website-left{
    width: 100%;
    --float: left;
    height: 100%;
    flex-grow: 1;
    background-color: var(--color-grey-backgrond);
}

.website-left-content {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 50px;
    padding-bottom: 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.website-center {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 50px;
    padding-bottom: 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-grey-light);
    

    display: none; /*media query thing */
}

.website-right {
    min-width: 34%;
    max-width: 34%;


    border-left: 1px solid var(--color-grey-light);
    box-sizing: border-box;
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 40px;
    padding-bottom: 10px;


    display: flex;
    flex-direction: column;
    gap: 20px;

    max-height: calc(100vh - 70px);

    position: -webkit-sticky;
    position: sticky;
    top: 70px;
    right: 0;

    --background-color: red;

}

.website-price-flex {
    display: flex;
    gap: 15px;
}

.website-image-large-wrapper {
    position: relative;
    height: 0;
    /* Formula is: (height / width * 100%) */
    padding-top: calc(720 / 1280 * 100%);
}

.website-image-large {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: auto; 
}

.bullet-flex {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.bullet {
    display: flex;
    gap: 10px;
}

.bullet-image {
    width: 30px;
    height: 30px;
    border-radius: 9999px;
}

.website-contact-flex {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.website-contact-item {
    display: flex;
    color: var(--color-blue);
}

.website-feature-card {
    width: 100%;
    box-sizing: border-box;
    background-color: var(--color-white);
    border-radius: 30px;
    box-shadow: var(--box-shadow-1);
    padding: 30px;
    display: flex;
    align-items: center;
    gap: 40px;
}

.website-feature-image {
    width: 50%;
    border-radius: 15px;
}

.website-feature-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
}

.home-bio-boxes {
    width: 900px;
    --justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.home-bio-card {
    width: 100%;
    box-sizing: border-box;
    background-color: var(--color-white);
    border-radius: 30px;
    box-shadow: var(--box-shadow-1);
    padding: 30px;
    display: flex;
    align-items: center;
    gap: 40px;
}

.home-bio-image {
    --width: 100%;
    --flex: 1;
    max-width: 33.3%;
    border-radius: 20px;
    box-sizing: border-box;
}

.home-bio-text {
    display: flex;
    flex-direction: column;
    gap: 20px;
    --flex: 1.5;
    --width: 100%;
    width: 100%;
    flex: 1;
    box-sizing: border-box;
}

.website-left-content-white {
    width: 100%;
    background-color: var(--color-white);
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 50px;
    padding-bottom: 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-top: 1px solid var(--color-grey-light);
    border-bottom: 1px solid var(--color-grey-light);
}

.website-process {
    display: flex;
    gap: 20px;
    align-items: center;
}

.process-text {
    display: flex;
    flex-direction: column;
    gap: 1px;
}

.process-title {
    font-size: var(--font-size-small);
    color: var(--color-orange);
    font-weight: 700;
}

.process-image {
    width: 40px;
    height: 40px;
    border-radius: 9999px;
}

.service {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.home-title-extra-container {
    text-align: center;
}

.home-brandon {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    gap: 50px;
    align-items: center;
    --min-width: 100%;
    box-sizing: border-box;
    width: 1000px;
    margin: 0 auto;
}

.home-brandon-text {
    display: flex;
    gap: 30px;
    
    align-self: flex-start;
    flex-direction: column;
    flex: 1;
    --align-self: flex-end;
    --justify-content: flex-end;
    --max-width: 20%;
}

.home-brandon-image {
    height: 300px;
    width: 300px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.301);
    border-radius: 99999px;
    border: 15px solid white;
    box-sizing: border-box;
}

.home-brandon-break {
    --display: none;
}

.page-banner-section {
    background-color: var(--color-green-light);
    background-color: rgb(29, 29, 29);
    --border-bottom: 10px dashed var(--color-green) 10px;
    background-image: linear-gradient(0deg, rgb(46, 46, 46) 0%, black 100%); 

    /* --cheese; */
    padding-top: 100px;
    padding-bottom: 100px;
}


/*
*********************************************************************************************************************
*********************************************************************************************************************
*********************************************************************************************************************
    Complex Elements
*********************************************************************************************************************
*********************************************************************************************************************
*********************************************************************************************************************
*/

.mobileVideo {
    width: 75%;
    height: auto;
    --margin-left: auto;
    --margin-right:  auto;
    display: flex;
    border-radius: 10px;

  }
  
  .mobileVideoContainer {
    width: 100%;
    background-color: var(--text-color);
    --box-sizing: border-box;
    --border-radius: 10px;
  }

.scrolling-container-wrapper {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-top: 70px;
    margin-bottom: 70px;
    padding-bottom: 30px;
}

.scrolling-container-wrapper::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 999px;
	background-color: #F5F5F5;
    background-color: var(--color-white);
    box-sizing: border-box;
    margin-left: 30px;
    margin-right: 30px;
}
.scrolling-container-wrapper::-webkit-scrollbar
{
	width: 12px;
	background-color: none
}
.scrolling-container-wrapper::-webkit-scrollbar-thumb
{
	border-radius: 999px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: var(--color-orange);
}

.scrolling-container {
    width: calc(500% - 100%);
    display: flex;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    gap: 15px;
}

.card-scrolling {
    background-color: var(--color-white);
    width: calc(100% - 20%);
    margin: 0 auto;
    box-shadow: var(--box-shadow-1);
    border-radius: 10px;
    box-sizing: border-box;
    overflow: auto;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.card-scrolling-paragraph {
    font-size: var(--font-size-0);
    color: var(--color-grey-dark);
    line-height: 175%;
    text-align: center;
    font-weight: 600;
}

.card-scrolling-image {
    width: 100%;
    height: auto;
    border: 2px solid var(--color-orange);
    box-sizing: border-box;
    border-radius: 5px;
}

.card-scrolling-paragraph-container {
    flex: 1;
}

.dots {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.dot-selected {
    width: 12px;
    height: 12px;
    background-color: var(--color-orange);
    border-radius: 999px;
}

.dot-other {
    width: 8px;
    height: 8px;
    background-color: var(--color-grey-light);
    border-radius: 999px;
}

.hero-container {
    position: relative;
    z-index: 1;
    max-width: 100%;
    box-sizing: border-box;
}

.hero-image-text-and-button {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    box-sizing: border-box;
}

.hero-background {
    background-color: var(--color-yellow);
    position: absolute;
    content: '';
    width: 120%;
    height: calc(100% - 150px);
    height: 100%;
    z-index: -1;
    transform: rotate(-5deg) translateX(-10%) translateY(0px);
    border-radius: 40px;
}

.hero-image-container {
    align-items: flex-start;
    flex: 1;
}

.hero-image {
    /* min-width: 0;
    width: 100%;
    --transform: translateX(-2.5%);
    --margin-left: 20px;
    --margin-right: 20px;
    box-sizing: border-box;
    height: auto !important;
    align-items: flex-start;
    flex: 1;
    display: block; */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: auto; 

}

.hero-image-wrapper {
    position: relative;
    height: 0;
    /* Formula is: (height / width * 100%) */
    padding-top: calc(467 / 752 * 100%);
}

.hero-text {
    font-size: var(--font-size-4);
    color: var(--color-white);
    color: var(--color-orange-light);
    text-shadow: 0px 0px 10px rgb(0, 0, 0, 0.5);
    --text-align: center;
    font-weight: 900;
    font-style: italic;
}

.hero-text-and-button {
    padding-left: 30px;
    padding-right: 30px;
    --transform: translateY(-200px);
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 50px;
    text-align: center;
    --align-items: left;

}

.hero-button-container {
    margin-left: auto;
    margin-right: auto;
    --display: flex;
    align-items: left;


}

.hero-button {
    --display: flex;
    gap: 15px;
    --align-items: center;
    align-items: left;

    border-radius: 5px;
    color: white;
    font-size: var(--font-size-2);
    background-color: var(--color-copper-green);
    background-image: linear-gradient(to right, rgb(78, 117, 102) 0%, #ffffff 51%, blue 100%);
    background-image: linear-gradient(-45deg, var(--color-blue) 0%, var(--color-green) 100%); 
    font-weight: 900;
    border: none;
    padding: 20px;
    padding-left: 60px;
    padding-right: 60px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
    font-family: 'Rajdhani-Bold';
}

.hero-button:hover {
    cursor: pointer;
    background-color: var(--color-copper-green-dark);
}

.faqs {
    width: 100%;

    box-sizing: border-box;
    box-shadow: var(--box-shadow-1);
    border-radius: 30px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    --gap: 30px;
    background-color: var(--color-white);
    
}


.faq {
    border-bottom: 1px solid var(--color-grey-light);
}

.faq:last-of-type {
    border-bottom: none;
}

.faq:hover {
    /* background-color: var(--color-orange-light) !important; */
}

.faq-top {
    display: flex;
    gap: 30px;
    padding: 30px;
}

.faq-top:hover {
    cursor: pointer;
}

.faq-question {
    flex: 1;
    font-size: var(--font-size-small);
    color: var(--color-black);
    line-height: 175%;
    --font-weight: bold;
}

.faq-answer {
    font-size: var(--font-size-small);
    color: var(--color-black);
    line-height: 175%;
}

.faq-bottom {
    padding: 30px;
    padding-top: 0px;
    display: none;
}

.faq-icon {
    width: 15px;
    height: auto;
}

@keyframes faqOut {
    0% {
        display: none;
        opacity: 0;
    }
    100% {
        display: block;
        opacity: 1;
    }
}

.arrows-and-image-container {
    position: relative;
    display: flex;
    justify-content: center;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;

}

.arrow-left {
    position: absolute;
    height: 100px;
    width: auto;
    top: calc(50% - 70px);
    bottom: calc(50% - 70px);
    padding: 20px;
    left: -140px;
    z-index: 99999999;
    -webkit-filter: drop-shadow(var(--arrow-drop-shadow));
    filter: drop-shadow(var(--arrow-drop-shadow));
    text-decoration: none;
}

.arrow-right {
    position: absolute;
    height: 100px;
    width: auto;
    top: calc(50% - 50px);
    bottom: calc(50% - 50px);
    padding: 20px;
    right: -140px;
    z-index: 99999999;
    -webkit-filter: drop-shadow(var(--arrow-drop-shadow));
    filter: drop-shadow(var(--arrow-drop-shadow));
    text-decoration: none;
}

.arrow-left:hover, .arrow-right:hover {
    cursor: pointer;
}

.rectangles-container {
    position: relative;
    height: 100%;
}

.rectangle {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: var(--arrow-drop-shadow);
    --border: 4px solid rgba(0, 0, 0, 0.5);
    box-sizing: border-box;


}
.rectangle-absolute {
    width: 100%;
    height: auto;
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    --border: 4px solid rgba(0, 0, 0, 0.5);

    box-sizing: border-box;
}

@keyframes rectangleLeft {
    0% {
        opacity: 1;
        display: block;
        transform: translateX(0px) translateY(0px) rotate(0deg);
    }
    100% {
        opacity: 0;
        display: none;
        transform: translateX(-100px) translateY(-50px) rotate(-10deg);

    }
}

@keyframes rectangleRight {
    0% {
        opacity: 1;
        display: block;
        transform: translateX(0px) translateY(0px) rotate(0deg);
    }
    100% {
        opacity: 0;
        display: none;
        transform: translateX(100px) translateY(-50px) rotate(10deg);

    }
}


.hero-smartphone-wrapper {
    position: relative;
    height: 0;
    /* Formula is: (height / width * 100%) */
    padding-top: calc(735 / 1800 * 100%);
    background-color: black;
    border-radius: 30px;

}

.hero-smartphone {
    --width: 100%;
    --height: auto;
    --border-top-right-radius: 30px;
    --border-top-left-radius: 30px;
    border-radius: 30px;;
    --z-index: 1;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: auto; 

    
}

.hero-smartphone-gradient {
    position: absolute;
    --background: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%,rgba(255, 0, 0, 0.0) 100%); /* W3C */
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    top: 30px;
    bottom: 0px;
    box-sizing: border-box;
    border-radius: 30px;
    --display: none;
    --background: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 50px,rgba(255, 0, 0, 0) 80%); /* W3C */
        bottom: 200px;
        padding-bottom: 200px;

}

.hero-smartphone-text {
    position: absolute;
    top: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--color-black);
    --text-shadow: 0px 0px var(--color-black);
    gap: 30px;
    text-align: center;
        align-items: center;
        top: auto;
        left: 0;
        right: 0;
        bottom: 80px;

}

.hero-smartphone-mobileblack {
    display: none;
    width: 100%;
    height: 150px;
    background-color: var(--color-black);
}


/*
*********************************************************************************************************************
*********************************************************************************************************************
*********************************************************************************************************************
    Media Query
*********************************************************************************************************************
*********************************************************************************************************************
*********************************************************************************************************************
*/

@media only screen and (max-width: 1000px), (orientation: portrait){

    .page-banner-section {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .mobileVideo {
        width: 100%;
        --border-radius: 10px;
        box-sizing: border-box;  
      }
    
      .mobileVideoContainer {
        border-radius: 0px;
        --background-color: rgb(0,0,0,0);
        border-radius: 10px;

      }

    .trio-of-images {
        flex-direction: column;
    }

    .header-desktop-logo {
        margin-left: 20px;
    }

    .home-brandon-image {
        width: 70%;
        height: 70%;
    }
    .title-small, .footer-text {
        font-size: 18px;
    }

    .banner-title {
        font-size: 40px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .test-title, #hero-id, .page-banner-title {
        font-size: 35px;
        
    }

    #hero-id {
        text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15);

    }

    .footer {
        align-items: start;
        gap: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .footer-text-container {
        flex-direction: column;
        align-items: left;
        text-align: left;
        gap: 20px;
    }
    .home-section {
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;
        text-align: left;
    }

    .home-art-products-container {
        flex-direction: column;
    }
    .home-art-products-container-row {
        flex-direction: column;
    }
    .home-section-2 {
        flex-direction: column;
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;
    }
    .hero-button {
        gap: 15px;
        width: 100%;
        padding: 15px;
    }

    #follow-button {
        padding-left: 60px;
        padding-right: 60px;
    }

    .Home {
        gap: 50px;
    }

    .Header {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        left: 0;
    }

    .header-desktop {
        display: none;
    }

    .header-mobile {
        display: flex;
    }

    .trio-content {
        flex-direction: column;
    }

    .button-container {
        margin-left: 30px;
        margin-right: 30px;
    }

    .hero-image-text-and-button {
        flex-direction: column;
        min-width: calc(100% - 40px);
        margin-left: 20px;
        margin-right: 20px;
        --align-items: flex-start;
    }

    .hero-text-and-button {
        padding: 0px;
    }

    .hero-background {
        transform: rotate(-15deg) translateX(-10%) translateY(0px);
    }

    .hero-image-container {
        /* flex: auto; 
        width: 100%;
        height: auto !important;
        trying this, possible delete this */
        margin: 0;
        width: 100%;
    }

    .hero-text {
        font-size: var(--font-size-3);
    }

    .hero-text-and-button {
        gap: 20px;
    }

    .features-flex-all {
        margin-left: 20px;
        margin-right: 20px;
        width: calc(100% - 40px);
    }

    .features-flex-two {
        flex-direction: column;
    }

    .contact-right {
        order: 0;
    }

    .contact-left {
        order: 1;
    }

    #contactRightFlexbox {
        padding-bottom: 0px;
    }

    .about-image-flex {
        --width: calc(100% - 40px);
        --margin-left: 20px;
        --margin-right: 20px;
        width: 50%;
        box-sizing: border-box;
    }

    .about-image {
        width: 100%;
    }

    .website-fullscreen-left {
        margin-left: 30px;
        margin-right: 30px;
        width: calc(100% - 9px);
        transform: translateX(-51px);
    }

    .website-fullscreen-right {
        margin-left: 30px;
        margin-right: 30px;
        width: calc(100% - 9px);
        --transform: translateX(51px);
    }

    .home-cards-flexbox {
        width: calc(100% - 40px);
        margin-left: 20px;
        margin-right: 20px;
        gap: 50px;
    }

    .why-bee-flexbox {
        flex-direction: column;
        width: calc(100% - 40px) !important;
        margin-left: 20px;
        margin-right: 20px;
    }

    .card-why-bee-image {
        width: 75% !important;
    }

    .arrows-and-image-container {
        margin-left: 40px !important;
        margin-right: 40px !important;
        min-width: calc(100% - 80px) !important;
    }

    .arrow-left {
        height: 50px;
        left: -50px;
        top: calc(50% - 45px);
        bottom: calc(50% - 45px);
    }
    
    .arrow-right {
        height: 50px;
        right: -50px;
        top: calc(50% - 45px);
        bottom: calc(50% - 45px);
    }

    .hero-smartphone-text {
        gap: 20px;
    }

    .title-larger {
        font-size: 30px;
    }

    .home-bio-card {
        flex-direction: column;
        --text-align: center;
    }

    .home-bio-image {
        min-width: 100%;
    }

    .home-bio-boxes {
        min-width: calc(100% - 40px) !important;
        margin-left: 20px !important;
        margin-right: 20px !important;
    }

    .home-title-extra-container {
        margin-left: 20px;
        margin-right: 20px;
    }

    .text-break-1, .text-break-2, .text-break-3 {
        display: none;
    }

    .card-basic {
        width: calc(100% - 40px);
        margin-left: 20px;
        margin-right: 20px;
    }

    

}

@media only screen and (orientation: landscape) {

    .banner {
        max-height: 500px;
        
    }
}
